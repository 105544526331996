<template>
    <div class="pt-5 pt-lg-0">
        <inner-page-card class="mb-5" heading="Transactions">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <validated-input label="Search" v-model="ext_search"/>
                </div>
            </div>

            <custom-vue-table class="d-sm-none-lg-block" :show-search-box="false"
                              v-if="currentUser.is_superuser || currentUser.is_ventura_admin"
                              title="" :extra-params="{ext_search}"
                              :fields="fields" :url="listUrl" :per-page="5" ref="table">
                <template #actions="{rowData}">
                    <div class="btn-group-tight">
                        <edit-btn icon="" text="Details" @click="viewDetails(rowData.txn_id)"/>
                    </div>
                </template>
            </custom-vue-table>
            <div v-else class="text-center py-7">
                <h4 class="font-inter-bold">Permission Denied!</h4>
                <p class="">Sorry, You have no permission to view this page.</p>
            </div>

            <transaction-mobile-view class="d-lg-none" :ext_search="ext_search"/>

        </inner-page-card>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import TransactionMobileView from '../../views/payment/TransactionMobileView';

export default {
    name       : 'Transactions',
    components : { TransactionMobileView },
    computed   : {
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            ext_search : '',
            listUrl    : urls.transactions.listUrl,
            fields     : [
                {
                    name      : 'sl_no',
                    sortField : 'sl_no',
                    title     : 'Sl No.'
                },
                {
                    name      : 'txn_id',
                    sortField : 'txn_id',
                    title     : 'Trn ID'
                },
                {
                    name      : 'membership_type',
                    sortField : 'membership_type',
                    title     : 'Membership'
                },
                {
                    name      : 'amount',
                    sortField : 'amount',
                    title     : 'Amount'
                },
                {
                    name      : 'user_name',
                    sortField : 'user_name',
                    title     : 'User'
                },
                {
                    name  : 'payment_status',
                    title : 'Payment Status'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Trn Initiated Date'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (item) {
            this.$router.push('/transactions/' + item + '/details/');
        }
    }
};
</script>

<style scoped>

</style>
